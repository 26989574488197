/* input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  } */

.heading {
  background-color: #b60d28;
  color: white;
  font-size: 10px;
  border: 1px solid black;
  padding: 2px 4px;
  width: 10%;
}

.data {
  font-size: 10px;
  border: 1px solid black;
  padding: 2px 4px;
  text-align: left;
  vertical-align: top;
}
